// src/App.js
import React, { useEffect, useState, useCallback } from "react";
import { GoogleMap, useJsApiLoader } from "@react-google-maps/api";
import CustomMapMarker from "./components/CustomMapMarker";
import CategoryMultiSelect from "./components/CategoryMultiSelect";
import SearchResult from "./components/SearchResult";
import ContactForm from "./components/ContactForm";
import { MapStylesGreen } from './components/MapStyles'
import './App.css';

const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
const LOCATIONS_ENDPOINT = process.env.REACT_APP_LOCATIONS_ENDPOINT;
const SUBMIT_ENDPOINT = process.env.REACT_APP_SUBMIT_ENDPOINT;
const libraries = ["places"];
const mapContainerStyle = {
  width: "100vw",
  height: "100vh",
  maxWidth: "100%"
};

const defaultCenter = {
  lat: 51.165691,
  lng: 10.451526
};

const defaultZoom = 6;

const categories = [
  {
    label: "PV & Speicher",
    value: "hasPvSpeicher",
    active: true,
    svg: () => (<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 54.2 47"><path fill="currentColor" d="m51.2 8 3-8zM46 21.8h.1l3.4-9.2h-.1z"></path><path fill="currentColor" d="M32.8 21.8H46l3.4-9.2H36.3zM51.2 8l3-8h-13l-3.1 8zM27.9 21.8l3.5-9.2H19.7l-3.5 9.2zM33.2 8l3.1-8H24.6l-3.1 8zM16.6 8l3-8H8.2l-3 8zM14.8 12.6H3.5L0 21.8h11.3zM27.4 40V25.5h-4.6V40c-6.5.4-11.5 2.7-11.5 5.5v1.6h27.5v-1.6c0-2.8-5-5.1-11.5-5.5Z"></path></svg>)
  },
  {
    label: "E-Mobilität",
    value: "hasEMobility",
    active: false,
    svg: () => (<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 76 59"><path fill="none" d="M18.7 10H32V4h-2.9c-5.7 0-10.4 6-10.4 6M36 4v6h16.5S43.3 4 39 4z"></path><path fill="currentColor" d="M59.7 23c0 .3-.1.6-.1 1v1c.6 1.7 2.1 3 4 3s3.4-1.3 3.9-3c0-.3.1-.7.1-1 0-2.2-1.8-4-4-4s-3.4 1.3-3.9 3M19.7 20.2c-1.8 0-3.3 1.3-3.7 2.9 0 .3-.2.7-.2 1v.8c.4 1.8 1.9 3.1 3.8 3.2h.1c1.9 0 3.5-1.4 3.9-3.2v-.8c0-2.2-1.8-4-4-4h-.1Z"></path><path fill="currentColor" d="M74.9 16.9c-.3-.8-1.1-2.2-3.4-3.3-3-1.5-8.5-3.2-12.7-4.2-2.2-1.5-8-4.7-13.7-7.3-2-.9-5.1-2.1-7.9-2.1h-7.5c-7.8 0-17.8 9-17.8 9l-6 1v11.3c.4-.1.9-.2 1.3-.2l-1.3.9v-.7c-3.5.9-6 4-6 7.8V31c0 4.4 3.6 8 8 8.1h54.3c2.2 0 4.1 1.8 4.1 4.1v1.9c0 2.2-1.8 4.1-4.1 4.1H43.6v4h18.6c4.4 0 8-3.6 8-8.1v-1.9c0-4.4-3.6-8-8-8.1H8.1C5.9 35.1 4 33.3 4 31v-1.9C4 26.9 5.8 25 8.1 25h4.7v-.8c0-2 .8-3.7 2.1-5 1.2-1.2 2.9-2 4.8-2 3.8 0 6.9 3.2 6.9 7V26h30.2v-2c0-2.2 1-4.2 2.6-5.5.3-.2.5-.4.8-.6 1.4-.8 3.1-1.2 4.9-.8 3.5.7 5.8 3.9 5.6 7.4v1.4h4.2l1.4-3.9s-1-4.8-1.1-5.1ZM32 10H18.7s4.7-6 10.4-6H32zm4 0V4h3c4.3 0 13.5 6 13.5 6z"></path><path fill="currentColor" d="M37 43h-5v3h-6v3h6v4h-6v3h6v3h5.1c3.3-.4 5.9-2.8 6.7-6v-4c-.8-3.1-3.5-5.6-6.7-6ZM7.3 21c-.4 0-.9.1-1.3.2v.7z"></path></svg>)
  },
  {
    label: "Wärmepumpe Installation",
    value: "hasWaermepumpeInstall",
    active: false,
    svg: () => (<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 69.2 55.28"><g fill="currentColor"><path d="M64.18 5.4v2.02h-7.43V0c-2.97 1.38-4.93 4.31-4.93 7.81 0 3.21 1.74 6.01 4.33 7.52v22.38c0 2.39 1.97 4.33 4.38 4.33s4.27-1.94 4.27-4.33V15.37a8.69 8.69 0 0 0 4.41-7.57c0-3.62-1.76-6.49-5.03-7.8zM61.7 37.7a1.24 1.24 0 1 1-2.48 0 1.24 1.24 0 0 1 2.48-.01Z"></path><path d="M48.5 22.08v27.7h-43v-27.7zm5.5-5.51H0v38.71h54z"></path><path d="M32.99 26.29h12.16v3.25H32.99zM32.99 32.1h12.16v3.25H32.99zM32.99 37.9h12.16v3.25H32.99zM19.21 24.87c-6.34 0-11.47 5.14-11.47 11.47s5.14 11.47 11.47 11.47 11.47-5.14 11.47-11.47-5.14-11.47-11.47-11.47m8.1 18.94c-1.28 1.39-3.08 1.82-5.21-.14-1.62-1.5-2.23-4.75-2.43-6.22-.14.06-.3.1-.47.1-.21 0-.41-.06-.59-.16-.79 1.36-2.48 3.93-4.44 4.67-2.71 1.03-4.22-.03-4.89-1.8-.68-1.77-.26-3.57 2.45-4.6 1.9-.73 4.76-.01 6.29.48.07-.38.31-.7.65-.87-.71-1.47-1.75-4.11-1.32-6.04.64-2.83 2.36-3.5 4.21-3.08s3.11 1.76 2.48 4.59c-.44 1.96-2.58 3.92-3.84 4.94.13.19.2.42.2.67 0 .13-.03.26-.07.38 1.48.07 4.78.41 6.4 1.91 2.13 1.96 1.85 3.79.57 5.18h.01Z"></path></g></svg>)
  },
  {
    label: "Wärmepumpe Integration",
    value: "hasWaermepumpeIntegration",
    active: false,
    svg: () => (<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 81.82 60.36"><g fill="currentColor"><path d="M48.5 26.7v27.7h-43V26.7zm5.5-5.51H0V59.9h54z"></path><path d="M32.99 30.9h12.16v3.25H32.99zM32.99 36.71h12.16v3.25H32.99zM32.99 42.51h12.16v3.25H32.99zM19.21 29.49c-6.34 0-11.47 5.14-11.47 11.47s5.14 11.47 11.47 11.47 11.47-5.14 11.47-11.47-5.14-11.47-11.47-11.47m8.1 18.94c-1.28 1.39-3.08 1.82-5.21-.14-1.62-1.5-2.23-4.75-2.43-6.22-.14.06-.3.1-.47.1-.21 0-.41-.06-.59-.16-.79 1.36-2.48 3.93-4.44 4.67-2.71 1.03-4.22-.03-4.89-1.8-.68-1.77-.26-3.57 2.45-4.6 1.9-.73 4.76-.01 6.29.48.07-.38.31-.7.65-.87-.71-1.47-1.75-4.11-1.32-6.04.64-2.83 2.36-3.5 4.21-3.08s3.11 1.76 2.48 4.59c-.44 1.96-2.58 3.92-3.84 4.94.13.19.2.42.2.67 0 .13-.03.26-.07.38 1.48.07 4.78.41 6.4 1.91 2.13 1.96 1.85 3.79.57 5.18h.01ZM55.63 37.95v-3h3.05c2.55 0 4.55 2.78 4.55 6.32v9.18c0 1.83 1.49 3.32 3.32 3.32h9.54v3h-9.54c-3.48 0-6.32-2.83-6.32-6.32v-9.18c0-2-.93-3.32-1.55-3.32z"></path><path d="M75.84 50.2a5.08 5.08 0 1 1-.002 10.162A5.08 5.08 0 0 1 75.84 50.2M55.63 28.99h21.36v3H55.63z"></path><path d="M76.74 35.57a5.08 5.08 0 1 0-.002-10.162 5.08 5.08 0 0 0 .002 10.162M55.63 22.41v3h3.05c2.55 0 4.55-2.78 4.55-6.32V9.91c0-1.83 1.49-3.32 3.32-3.32h9.54v-3h-9.54c-3.48 0-6.32 2.83-6.32 6.32v9.18c0 2-.93 3.32-1.55 3.32z"></path><path d="M75.84 10.16a5.08 5.08 0 1 0-.002-10.162 5.08 5.08 0 0 0 .002 10.162"></path></g></svg>)
  }
];

const App = () => {

  const [locations, setLocations] = useState([]);
  const [filteredLocations, setFilteredLocations] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchString, setSearchString] = useState("");
  const [map, setMap] = useState(null);
  const [showResult, setShowResult] = useState(false);
  const [currentCenter, setCurrentCenter] = useState(null);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [filterCity, setFilterCity] = useState("");
  const [filterServices, setFilterServices] = useState([]);

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: GOOGLE_MAPS_API_KEY,
    libraries
  });

  // Fetch locations from API endpoint on mount
  useEffect(() => {
    if (locations.length > 0) {
      return;
    }

    /**
     * NOTE: Request is not working in WordPress because preview mode overrides Authorization header
     */
    const fetchData = async () => {
      const username = process.env.REACT_APP_CONTACT_FORM_AUTH_USERNAME;
      const password = process.env.REACT_APP_CONTACT_FORM_AUTH_PASSWORD;
      const authHeader = 'Basic ' + btoa(`${username}:${password}`);
      const response = await fetch(LOCATIONS_ENDPOINT, {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': authHeader,
          }
      });

      if (response.ok) {
        const responseText = await response.text();
        const result = JSON.parse(responseText);
        if (result.data) {
          setLocations(result.data);
          setFilteredLocations(result.data);
        }
        setSelectedCategories({
          hasPvSpeicher: true,
          hasEMobility: false,
          hasWaermepumpeInstall: false,
          hasWaermepumpeIntegration: false
        });
      }
    }

    fetchData();
  }, [locations]);

  // filter location by services and search
  const filterLocations = useCallback((newCenter) => {

    if (newCenter) {
      setCurrentCenter(newCenter);
    }

    var filtered = locations;

    // filter by category
    if (selectedCategories.hasPvSpeicher ||
      selectedCategories.hasEMobility ||
      selectedCategories.hasWaermepumpeInstall ||
      selectedCategories.hasWaermepumpeIntegration
    ) {
      filtered = filtered.filter((location) => {
          var filter = true;
          if (selectedCategories.hasPvSpeicher) {
            filter &= location.hasPvSpeicher;
          }
          if (selectedCategories.hasEMobility) {
            filter &= location.hasEMobility;
          }
          if (selectedCategories.hasWaermepumpeInstall) {
            filter &= location.hasWaermepumpeInstall;
          }
          if (selectedCategories.hasWaermepumpeIntegration) {
            filter &= location.hasWaermepumpeIntegration;
          }
          return filter;
      });
    }

    // - calculate distance for locations
    // - sort by distance
    // - get shortest locations
    const center = newCenter ? newCenter : currentCenter;
    if (center) {
      // get nearest 10
      filtered = filtered.map(item => {
          return {
              ...item,
              distance: (Math.round(haversineDistance(center.lat, center.lng, item.lat, item.lng) * 10) / 10).toFixed(1)
          };
      })
      .sort((a, b) => a.distance - b.distance) // Sort by distance in ascending order
      .slice(0, 20);

      // prioritize gold partner
      filtered = filtered.sort((a, b) => {
        if (a.isGoldPartner && !b.isGoldPartner) return -1; // a is gold partner, comes first
        if (!a.isGoldPartner && b.isGoldPartner) return 1;  // b is gold partner, comes first
        return 0; // No changes if both have the same gold status
      });

      // get first 10
      filtered = filtered.slice(0, 10);

      setShowResult(true);
    }

    setFilteredLocations(filtered);
  }, [locations, selectedCategories, currentCenter]);

  // refilter when selectedCategories changes
  useEffect(() => {
    filterLocations();

    const services = [];
    if (selectedCategories.hasPvSpeicher) {
      services.push(categories[0].label);
    }
    if (selectedCategories.hasEMobility) {
      services.push(categories[1].label);
    }
    if (selectedCategories.hasWaermepumpeInstall) {
      services.push(categories[2].label);
    }
    if (selectedCategories.hasWaermepumpeIntegration) {
      services.push(categories[3].label);
    }
    setFilterServices(services.join(', '));
  }, [filterLocations, selectedCategories, locations]);

  function haversineDistance(lat1, lon1, lat2, lon2) {
    const r = 6371; // km
    const p = Math.PI / 180;
    const a = 0.5 - Math.cos((lat2 - lat1) * p) / 2
                  + Math.cos(lat1 * p) * Math.cos(lat2 * p) *
                    (1 - Math.cos((lon2 - lon1) * p)) / 2;
    return 2 * r * Math.asin(Math.sqrt(a));
  }

  const handleSearch = () => {
    if (searchQuery) {
      const geocoder = new window.google.maps.Geocoder();

      geocoder.geocode({ address: searchQuery, language: 'de' }, (results, status) => {
        if (status === window.google.maps.GeocoderStatus.OK) {

          const geoLocation = results[0].geometry.location
          // const location = { lat: geoLocation.lat(), lng: geoLocation.lng() }
          const addressComponents = results[0].address_components
          const filteredCity = addressComponents.filter((address_component) => address_component.types.includes('locality'))
          // const filteredPostal = addressComponents.filter((address_component) => address_component.types.includes('postal_code'))
          const city = filteredCity.length ? filteredCity[0].long_name : ''
          // const zip = filteredPostal.length ? filteredPostal[0].long_name : ''
          const name = `${city}`

          setSearchString(name);
          setSearchQuery(name);
          setFilterCity(city);

          // Get the coordinates of the search result
          const newCenter = {
            lat: geoLocation.lat(),
            lng: geoLocation.lng(),
          };

          // Set the map to the new center and zoom in
          map.panTo(newCenter);
          map.setZoom(9);

          // show/hide everything out of range
          filterLocations(newCenter);
        } else {
          console.error('Geocode was not successful for the following reason: ' + status);
        }
      });
    }
  };

  const handleCategoryMultiSelectChange = (selected) => {
    setSelectedCategories(selected);
  }

  // on enter:
  // - set map marker as active
  // - move to position
  const handleSearchResultItemEnter = (location, index) => {
    map.panTo({lat: location.lat, lng: location.lng});
    setFilteredLocations((prevLocations) =>
      prevLocations.map((location, i) =>
        i === index
          ? { ...location, active: true }
          : location
      )
    );
  };

  // on leave: set map marker as inactive
  const handleSearchResultItemLeave = (location, index) => {
    setFilteredLocations((prevLocations) =>
      prevLocations.map((location, i) =>
        i === index
          ? { ...location, active: false }
          : location
      )
    );
  }

  // show on leave: set map marker as inactive
  const handleSearchResultItemClicked = (location, index) => {
    setSelectedLocation(location);
    // @set
  }

  const handleContactFormClose = () => {
    setSelectedLocation(null);
  }

  const handleMarkerClick = (location) => {
    // @todo:

    setSearchString(`${location.zipcode} ${location.city}`); // Set the city name
    setSearchQuery(`${location.zipcode} ${location.city}`);

    // Set the map to the new center and zoom in
    const newCenter = { lat: location.lat, lng: location.lng };
    map.panTo(newCenter);
    map.setZoom(9);

    // Show/hide locations based on proximity
    filterLocations(newCenter);

    // Set location
    setSelectedLocation(location);
  }

  const handleGeolocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          // Get the coordinates of the search result

          const { latitude, longitude } = position.coords;

          const geocoder = new window.google.maps.Geocoder();
          const latLng = { lat: latitude, lng: longitude };

          // Reverse geocode to get address components, including the city
          geocoder.geocode({ location: latLng }, (results, status) => {
            if (status === window.google.maps.GeocoderStatus.OK && results[0]) {
              const addressComponents = results[0].address_components;
              const filteredCity = addressComponents.filter((component) =>
                component.types.includes('locality')
              );
              // const filteredPostal = addressComponents.filter((component) =>
              //   component.types.includes('postal_code')
              // );

              const city = filteredCity.length ? filteredCity[0].long_name : '';
              // const zip = filteredPostal.length ? filteredPostal[0].long_name : '';
              const name = `${city}`;

              setSearchString(name); // Set the city name
              setSearchQuery(name);
              setFilterCity(city);

              // Set the map to the new center and zoom in
              const newCenter = { lat: latitude, lng: longitude };
              map.panTo(newCenter);
              map.setZoom(9);

              // Show/hide locations based on proximity
              filterLocations(newCenter);
            } else {
              console.error('Geocode was not successful for the following reason: ' + status);
            }
          });
        }
      );
    }
  }

  return (
    <div className='e3dcp map-container'>
      <div className='map-bg-gradient'></div>
      <div className='map-overlay-container'>
        <ContactForm
          location={selectedLocation}
          action={SUBMIT_ENDPOINT}
          onClose={handleContactFormClose}
          filterCity={filterCity}
          filterServices={filterServices}
        />
        <div className="row">
          <div className='col-12'>
            {/* @todo: component for search input */}
            <div className='map-search p-3 d-flex'>
              <div
                onClick={handleGeolocation}
                className='map-search-geolocation'>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24"><path stroke="none" d="M0 0h24v24H0z"></path><path d="M9 12a3 3 0 1 0 6 0 3 3 0 1 0-6 0"></path><path d="M4 12a8 8 0 1 0 16 0 8 8 0 1 0-16 0M12 2v2M12 20v2M20 12h2M2 12h2"></path></svg>
              </div>
              <input
                className='map-search-input'
                type="text"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === 'Enter' && searchQuery.length > 2) {
                    handleSearch();
                  }
                }}
                placeholder="PLZ oder Ort"
              />
              <button className='map-search-button' onClick={handleSearch}>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" className="map-search-button-icon"><path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 10a7 7 0 1 0 14 0 7 7 0 0 0-14 0m18 11-6-6"></path></svg>
              </button>
            </div>
          </div>
        </div>
        <div className="row">
          <div className='col-12'>
            {/* Checkboxes */}
            <CategoryMultiSelect
              categories={categories}
              onChange={handleCategoryMultiSelectChange}
            />
          </div>
        </div>
        <div className="row">
          <div className='col-12'>
            {/* Search Result */}
            <SearchResult
              showResult={showResult}
              locations={filteredLocations}
              onSearchResultItemEnter={handleSearchResultItemEnter}
              onSearchResultItemLeave={handleSearchResultItemLeave}
              onSearchResultItemClicked={handleSearchResultItemClicked}
              searchString={searchString}
              selectedCategories={selectedCategories}
              selectedLocation={selectedLocation}
            />
          </div>
        </div>

      </div>

      { isLoaded && filteredLocations.length > 0 ? (
        <GoogleMap
          mapContainerStyle={mapContainerStyle}
          zoom={defaultZoom}
          center={defaultCenter}
          onLoad={(map) => setMap(map)}
          options={{
            styles: MapStylesGreen,
            gestureHandling: 'cooperative',
            disableDefaultUI: true
          }}
        >
          {filteredLocations.map((location, index) => (
            <CustomMapMarker
              key={index}
              location={location}
              onClick={handleMarkerClick}
              allowTooltipShown={showResult}
            />
          ))}
        </GoogleMap>
      ): (
        <div>Loading map...</div>
      )}
    </div>
  );
};

export default App;
